body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.w3-animate-left {
    position: relative;
    animation: animateleft 0.4s
}

@keyframes animateleft {
    from {
        left: -300px;
        opacity: 0
    }
    to {
        left: 0;
        opacity: 1
    }
}

.nooma-background {
    background: url('/public/n_background.png') repeat center/25%
}

@font-face {
    font-family: "noomaFont";
    src: url("../public/fonts/georgia.ttf");
}

@font-face {
    font-family: "noomaFont2";
    src: url("../public/fonts/georgia-2.ttf");
}

@tailwind base;
@tailwind components;
@tailwind utilities;


@layer components {

    .w3-sidebar {
        @apply h-full w-2/3 md:w-1/5 lg:w-1/5 bg-white fixed z-10 overflow-auto
    }

    .input {
        @apply border-gray-400 border rounded-md p-2 w-full
    }
}